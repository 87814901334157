import React from "react";


const RichTextEditor = () => {
  return (
   <div>
       je suis dans la partie message
   </div>
  );
};

export default RichTextEditor;
