export enum GDINPaths {
  Home = "/dashboard/",
  Employee = "/dashboard/workers",
  EmployeeDetails = "/dashboard/workers/:id",
  Customers = "/dashboard/customers",
  Department = "/dashboard/professions",
  DepartmentDetails = "/dashboard/professions/:id",
  Jobs = "/dashboard/jobs",
  Payroll = "/dashboard/payroll",
  Activities = "/dashboard/activities",
  Events = "/dashboard/events",
  Account = "/dashboard/account",
  Holidays = "/dashboard/holidays",
  Report = "/dashboard/report",
  Users = "/dashboard/users",
  FileManager = "/dashboard/filemanager",
  Messages = "/dashboard/messages",
  Calendar = "/dashboard/calendar",
  Contact = "/dashboard/contact",
  Profile = "/dashboard/profile",
  Myinfo = "/dashboard/profile/myinfo",
  People = "/dashboard/profile/people",
  Reference = "/dashboard/profile/reference",
  Commercial = "/dashboard/profile/commercial",
  Job = "/dashboard/profile/myinfo/job",
  TimeOff = "/dashboard/profile/myinfo/timeoff",
  Emergency = "/dashboard/profile/myinfo/emergency",
  Document = "/dashboard/profile/myinfo/document",
  DevPersonel = "/dashboard/profile/myinfo/devpersonel",
}
